import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import './BarGraph.css';

export default function BarGraph({ levelCount, title }) {
    // Extracting the counts from the levelCount object
    const counts = Object.values(levelCount);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white' }}>
            <div>{title}</div>
            <BarChart
                //xAxis={[{ scaleType: 'band', data: Object.keys(levelCount), colorMap: { type: 'piecewise', colors: ['blue'] } }]} // Levels as x-axis
                xAxis={[{
                    scaleType: 'band', data: Object.keys(levelCount),
                    sx: {
                        '.MuiChartsAxis-tickLabel': { fill: '#dadada' }
                    } // Change label color
                }]}
                series={[{ data: counts }]} // Array of data points (counts)
                width={300}
                height={200}
                yAxis={[{ label: 'Count' }]}
                grid={{ horizontal: true }}
                disableAxisListener={true}
                tooltip={{ trigger: 'axis' }}
            /></div>
    );
}
