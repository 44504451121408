import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';  // Use BrowserRouter
import App from './App';
import reportWebVitals from './reportWebVitals';
import './custom.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


//Load something on homepage
//Add section to view card information Left adds/ctrl click opens modal
//Add limits [check card type, no more than 1 leader]
//Fix Import to take in more than a link
//Two line names, idk
//Add more filtering
//add full image view
//Sort Deckbuilder by Cost and Leader


//LIVE BUGS
//Export not working because the /FusionWorld and /OPTCG arent actual endpoints?


/*
-Sorting of the deckbuilder cards
-Right Click to view big version of the card
-Deckbuilder layout added: -1, +1, Clear All
-Deckbuilder added category count for Leader, Character, etc
-Adjusted the size of cardlist vs builder


Known Bugs:
-Export button doesnt work, guess i was forcing it to work on local, so I need to test it on live URL now to see whats up. Shouldn't be huge problem
-Also means Import doesnt work
-Search and Color Filters BOXES don't clear when you hit clear, the backend it does clear
-AA's load a bit slow, not sure whats causing that exactly, might be CloudFlare not hooked up yet, so caching sucks. NO AA's for DBZ so we good there


Priority List atm
-Bug list fixes
-Use CloudFlare to route my netlify hosting
-Clean up the deckbuilder stats
-Organize the navbar and buttons a bit more
-Card information when you right click instead of just image
-Metadata
*/



//Figure out scrollbar
//Only 1 leader allowed
//Add deck stats to show how many of each cost
//Filter boxes need to be cleared



//Real TODO List
//Change URL system to be shorter, maybe change the system so AA's are just pictures, instead of being its own card in the data. Could help with preformance too



//Notes for help: How to optimize mobile, i want it so you can clikc a button in nav to switch between the two views, seems the easiest way to single out each component for mobile view



// 2/8 TODO:
// Search by card ID as an options, add zooming to card list, sort set dropdown, full screen inspect should be centered