// GameOptions.js
import React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { Button } from '@mui/material';

const GameOptions = ({ setSelectedCardType, selectedCardType, onEnter }) => {
    const OPSET = process.env.REACT_APP_OP_SET;
    const defaultImage = 'assets/LandingComingSoon.png'; // Define your backup image here

    const optionData = {
        optcg: {
            title: 'One Piece',
            destination: `https://egmanevents.com/one-piece-${OPSET}`,
            structureDecks: true,
            tournamentLists: true,
        },
        fusionworld: {
            title: 'Fusion World',
            destination: 'https://egmanevents.com/fb03-team-decks',
            structureDecks: true,
            tournamentLists: true,
        },
        digimon: {
            title: 'Digimon',
            destination: 'https://egmanevents.com/digi-bt16-format/egman-deck-lists',
            structureDecks: true,
            tournamentLists: true,
        },
        unionarena: {
            title: 'Union Arena',
            destination: 'https://egmanevents.com/ua-wave-1-staff',
            structureDecks: true,
            tournamentLists: true,
        },
        gundam: {
            title: 'Gundam',
            destination: 'https://egmanevents.com/gundam',
            structureDecks: false, // Not available
            tournamentLists: false, // Not available
        },
    };

    const options = [
        { option: 'Deck Builder', image: `assets/${selectedCardType.toLowerCase()}full.png`, destination: 'cardList', clickable: true },
        {
            option: 'Structure Decks',
            image: optionData[selectedCardType]?.structureDecks ? `assets/${selectedCardType.toLowerCase()}structure.png` : defaultImage,
            destination: optionData[selectedCardType]?.structureDecks ? 'structureDecks' : null, // Set to null if not available
            clickable: optionData[selectedCardType]?.structureDecks, // Check availability
        },
        {
            option: 'Tournament Lists',
            image: optionData[selectedCardType]?.tournamentLists ? `assets/${selectedCardType.toLowerCase()}tournament.png` : defaultImage,
            destination: optionData[selectedCardType]?.tournamentLists ? optionData[selectedCardType].destination : null, // Set to null if not available
            clickable: optionData[selectedCardType]?.tournamentLists, // Check availability
        }
    ];

    const handleOpenLink = (url) => {
        window.open(url, '_blank');
    };

    const onBack = () => {
        setSelectedCardType('');
        onEnter('landing');
    };

    return (
        <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="landing-page" style={{ color: '#DADADA', textAlign: 'center' }}>
                <h1>Exploring {optionData[selectedCardType]?.title || 'Game'}</h1>
                <p>Choose an option below to look at the content</p>
            </div>
            <Box component="ul" sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', p: 0, m: 0, justifyContent: 'center', width: '75%' }}>
                {options.map(({ option, image, destination, clickable }) => (
                    <Card
                        key={option}
                        component="li"
                        sx={{
                            flexBasis: 'calc(50% - 16px)',
                            minWidth: 0,
                            '&:hover': clickable ? {
                                border: '3px solid #1E1F21',
                                filter: 'brightness(85%)',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                cursor: 'pointer',
                            } : {
                                cursor: 'not-allowed', // Change cursor for non-clickable options
                                opacity: 0.5, // Dim non-clickable cards
                            },
                        }}
                        onClick={() => {
                            if (clickable) {
                                if (destination && destination.startsWith('http')) {
                                    handleOpenLink(destination);
                                } else if (destination) {
                                    onEnter(destination);
                                }
                            }
                        }}
                    >
                        <CardCover>
                            <img
                                src={image}
                                alt={`${option} Image`}
                                loading="lazy"
                                onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }}
                            />
                        </CardCover>
                        <CardContent style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <Typography
                                level="body-lg"
                                fontWeight="lg"
                                textColor="#fff"
                                mt={{ xs: 12, sm: 18 }}
                                sx={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                }}
                            >
                                {option}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Box>
            <Button
                variant="text"
                onClick={onBack}
                sx={{
                    color: '#DADADA',
                    textDecoration: 'underline',
                }}
            >
                Back to Game Selection
            </Button>
        </div>
    );
};

export default GameOptions;
