// NavBar.js
import React, { useState } from 'react';
import './NavBar.css'; // Import your styles if needed
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const NavBar = ({ onCardTypeChange, selectedCardType, onEnter }) => {
    const OPSET = process.env.REACT_APP_OP_SET;
    const [gameAnchorEl, setGameAnchorEl] = useState(null);
    const [decklistsAnchorEl, setDecklistsAnchorEl] = useState(null);

    const handleCardTypeChange = (cardType) => {
        onCardTypeChange(cardType);
        onEnter('gameOptions');
        handleGameClose();
    };

    const handleGameClick = (event) => {
        setGameAnchorEl(event.currentTarget);
    };

    const handleDecklistsClick = (event) => {
        setDecklistsAnchorEl(event.currentTarget);
    };

    const handleGameClose = () => {
        setGameAnchorEl(null);
    };

    const handleDecklistsClose = () => {
        setDecklistsAnchorEl(null);
    };

    return (
        <div className="nav-bar">
            <div className="container NavContainer">
                <div className="LeftNavContainer">
                    <div className="logoContainer">
                        <img
                            src='assets/EgmanEventsLogoEAEA.png'
                            className='logo'
                            alt="egmanlogo"
                            onClick={() => window.location.href = "https://deckbuilder.egmanevents.com/"}
                        />
                    </div>
                    <Button
                        id="NavButton"
                        onClick={() => window.location.href = "https://deckbuilder.egmanevents.com/"}
                    >
                        Home
                    </Button>
                    <Button
                        id="NavButton"
                        onClick={handleGameClick}
                    >
                        Build
                    </Button>
                    <Menu
                        anchorEl={gameAnchorEl}
                        open={Boolean(gameAnchorEl)}
                        onClose={handleGameClose}
                    >
                        <MenuItem onClick={() => handleCardTypeChange('optcg')}>One Piece</MenuItem>
                        <MenuItem onClick={() => handleCardTypeChange('fusionworld')}>Fusion World</MenuItem>
                        <MenuItem onClick={() => handleCardTypeChange('digimon')}>Digimon</MenuItem>
                        <MenuItem onClick={() => handleCardTypeChange('unionarena')}>Union Arena</MenuItem>
                        <MenuItem onClick={() => handleCardTypeChange('gundam')}>Gundam</MenuItem>
                    </Menu>
                    <Button
                        id="NavButton"
                        onClick={handleDecklistsClick}
                    >
                        Tournaments
                    </Button>
                    <Menu
                        anchorEl={decklistsAnchorEl}
                        open={Boolean(decklistsAnchorEl)}
                        onClose={handleDecklistsClose}
                    >
                        <MenuItem onClick={() => {
                            window.open('https://www.egmanevents.com/one-piece', '_blank');
                            handleDecklistsClose();
                        }}>One Piece</MenuItem>
                        <MenuItem onClick={() => {
                            window.open('https://www.egmanevents.com/dbs-fusion-world', '_blank');
                            handleDecklistsClose();
                        }}>Fusion World</MenuItem>
                        <MenuItem onClick={() => {
                            window.open('https://www.egmanevents.com/digimon', '_blank');
                            handleDecklistsClose();
                        }}>Digimon</MenuItem>
                        <MenuItem onClick={() => {
                            window.open('https://egmanevents.com/union-arena', '_blank');
                            handleDecklistsClose();
                        }}>Union Arena</MenuItem>
                    </Menu>
                    {selectedCardType.toLowerCase() === "optcg" && (
                        <Button id="NavButton"
                            onClick={() => {
                                const baseUrl = `https://egmanevents.com/one-piece-${OPSET}`;
                                window.open(baseUrl, '_blank');
                            }}>
                            {OPSET.toUpperCase()}
                        </Button>
                    )}
                    {selectedCardType.toLowerCase() === "fusionworld" && (
                        <Button id="NavButton"
                            onClick={() => {
                                const baseUrl = "https://egmanevents.com/fb04-team-decks";
                                window.open(baseUrl, '_blank');
                            }}>
                            FB04
                        </Button>
                    )}
                    {selectedCardType.toLowerCase() === "digimon" && (
                        <Button id="NavButton"
                            onClick={() => {
                                const baseUrl = "https://egmanevents.com/sb20-staff-decks ";
                                window.open(baseUrl, '_blank');
                            }}>
                            Special Booster 2.0
                        </Button>
                    )}
                    {selectedCardType.toLowerCase() === "unionarena" && (
                        <Button id="NavButton"
                            onClick={() => {
                                const baseUrl = "https://egmanevents.com/ua-wave-1-staff";
                                window.open(baseUrl, '_blank');
                            }}>
                            Wave 1
                        </Button>
                    )}
                </div>
                <div className="RightNavContainer">
                    <img
                        src='assets/discord.png'
                        className='SocialIcon'
                        alt="discord"
                        onClick={() => window.open('https://discord.gg/pXpDa5JVxw', '_blank')}
                    />
                    <img
                        src='assets/youtube.png'
                        className='SocialIcon'
                        alt="youtube"
                        onClick={() => window.open('https://www.youtube.com/@TheEgman', '_blank')}
                    />
                </div>
            </div>
        </div>
    );
};

export default NavBar;
