import React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

const LandingPage = ({ setSelectedCardType, onEnter }) => {
    const handleGameSelection = (gameType) => {
        setSelectedCardType(gameType);
        onEnter('gameOptions');
    };


    return (
        <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="landing-page" style={{ color: '#DADADA', textAlign: 'center' }}>
                <h1>Welcome to the Egman Deck Builder!</h1>
                <p>Choose a game below to explore different options</p>
            </div>

            <Box component="ul" sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', p: 0, m: 0, width: '75%' }}>
                {[
                    { game: 'One Piece', type: 'optcg', image: 'assets/optcgfull.png', clickable: true },
                    { game: 'Fusion World', type: 'fusionworld', image: 'assets/fusionworldfull.png', clickable: true },
                    { game: 'Digimon', type: 'digimon', image: 'assets/digimonfull.png', clickable: true },
                    { game: 'Union Arena', type: 'unionarena', image: 'assets/unionarenafull.png', clickable: true },
                    { game: 'Gundam', type: 'gundam', image: 'assets/gundamfull.png', clickable: true },
                    { game: 'Coming Soon...', type: 'comingsoon', image: 'assets/LandingComingSoon.png', clickable: false }
                ].map(({ game, type, image, clickable }) => (
                    <Card
                        key={type}
                        component="li"
                        sx={{
                            flexBasis: 'calc(50% - 16px)',
                            minWidth: 0,
                            flexGrow: 1,
                            '&:hover': clickable ? {
                                border: '3px solid #1E1F21',
                                filter: 'brightness(85%)',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                cursor: 'pointer',
                            } : {
                                cursor: 'not-allowed', // Change cursor for non-clickable options
                                opacity: 0.5, // Dim non-clickable cards
                            },
                        }}
                        onClick={clickable ? () => handleGameSelection(type) : null}
                    >
                        <CardCover>
                            <img src={image} alt={`${game} Image`} loading="lazy" className="Landing" />
                        </CardCover>
                        <CardContent style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <Typography
                                level="body-lg"
                                fontWeight="lg"
                                textColor="#fff"
                                mt={{ xs: 12, sm: 18 }}
                                sx={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                }}
                            >
                                {game}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </div>
    );
};

export default LandingPage;
