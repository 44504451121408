import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Button from '@mui/material/Button';

const StructureDecks = ({ setSelectedCardType, selectedCardType, onEnter }) => {
    const [structureDecks, setStructureDecks] = useState([]);

    useEffect(() => {
        // Fetch JSON data from the public directory
        fetch(`/data/decks/ST.json`)
            .then((response) => response.json())
            .then((data) => {
                // Access data based on selectedCardType
                const decks = data[selectedCardType] || [];
                setStructureDecks(decks);
            })
            .catch((error) => console.error('Error fetching JSON data:', error));
    }, [selectedCardType]);

    const onBack = () => {
        setSelectedCardType('');
        onEnter('landing');
    };

    const handleOpenLink = (url) => {
        window.location.href = url;
    };

    return (
        <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="landing-page" style={{ color: '#DADADA', textAlign: 'center' }}>
                <h1>Choose a Structure Deck</h1>
                <p>Select a deck to view more details</p>
            </div>
            <Box
                component="ul"
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexWrap: 'wrap',
                    p: 0,
                    m: 0,
                    justifyContent: 'center',
                    width: '75%',
                }}
            >
                {structureDecks.map(({ label, url, image }) => {
                    // Construct the primary image path
                    const primaryImage = `/card_images/${selectedCardType}/${image}.webp`;
                    console.log(primaryImage);
                    // Fallback image path if the primary image is not found
                    const fallbackImage = `/assets/LandingComingSoon.png`;

                    return (
                        <Card
                            key={label}
                            component="li"
                            sx={{
                                flexBasis: 'calc(33% - 16px)', // Adjust the size of the cards
                                minWidth: 0,
                                height: '100%',
                                '&:hover': {
                                    border: '3px solid #1E1F21',
                                    filter: 'brightness(85%)',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={() => handleOpenLink(url)}
                        >
                            <CardCover>
                                <img
                                    src={primaryImage}
                                    alt={`${label} Image`}
                                    loading="lazy"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = fallbackImage;
                                    }}
                                    style={{
                                        position: 'absolute',
                                        objectFit: 'cover',
                                        objectPosition: '50% 15%',
                                    }}
                                />
                            </CardCover>
                            <CardContent style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <Typography
                                    level="h5"
                                    fontWeight="lg"
                                    textColor="#fff"
                                    sx={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                        padding: '8px 12px',
                                        borderRadius: '4px',
                                        marginTop: '8px',
                                    }}
                                >
                                    {label}
                                </Typography>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
            <Button
                variant="text"
                onClick={onBack}
                sx={{
                    color: '#DADADA',
                    textDecoration: 'underline',
                    marginTop: '20px',
                }}
            >
                Back to Game Selection
            </Button>
        </div>
    );
};
export default StructureDecks;
