import React, { useEffect } from 'react';

const AdComponent = ({ slotId, type }) => {
    useEffect(() => {
        // Create the script element
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = false;
        script.setAttribute('data-cfasync', 'false');
        script.innerHTML = `
            $MMT = window.$MMT || {};
            $MMT.cmd = $MMT.cmd || [];
            $MMT.cmd.push(function() {
                $MMT.${type}.slots.push(["${slotId}"]);
            });
        `;

        // Append the script to the body
        document.body.appendChild(script);

        // Cleanup script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, [slotId]); // Re-run effect when slotId changes

    return (
        <div id={`mmt-${slotId}`}></div>
    );
};

export default AdComponent;
