import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './TestHands.css';

const TestHands = ({ cards, selectedCardType }) => {
    // State to manage the drawing deck and the drawn hand
    const [drawDeck, setDrawDeck] = useState([]);
    const [hand, setHand] = useState([]);
    const [showModal, setShowModal] = useState(false);

    // Mapping of selectedCardType to number of cards to draw
    const cardTypeDrawCounts = {
        optcg: 5,
        digimon: 5,
        unionarena: 7,
        fusionworld: 6,
        // Add more mappings as needed
    };

    // Function to filter out excluded cards
    const filterExcludedCards = (cards) => {
        return cards.filter(card => {
            const category = card["Category"] ? card["Category"].toLowerCase() : '';
            return category !== 'digi-egg' && category !== 'leader';
        });
    };

    // Initialize the drawDeck when cards prop changes
    useEffect(() => {
        const clonedDeck = filterExcludedCards(JSON.parse(JSON.stringify(cards)));
        setDrawDeck(clonedDeck);
    }, [cards]);

    // Function to draw a starting hand based on selectedCardType
    const drawStartingHand = () => {
        let newDrawDeck = filterExcludedCards(JSON.parse(JSON.stringify(cards)));
        let drawnHand = [];
        const drawCount = cardTypeDrawCounts[selectedCardType] || 5; // Default to 5 if type is not found

        while (drawnHand.length < drawCount && newDrawDeck.length > 0) {
            let randomIndex = Math.floor(Math.random() * newDrawDeck.length);
            let card = { ...newDrawDeck[randomIndex] }; // Clone the card object
            if (card.count > 0) {
                drawnHand.push(card);
                newDrawDeck[randomIndex].count--; // Decrease the count in the drawing deck
            }

            // Remove the drawn card from the drawing deck if count is zero
            if (newDrawDeck[randomIndex].count === 0) {
                newDrawDeck.splice(randomIndex, 1);
            }
        }

        setHand(drawnHand);
        setDrawDeck(newDrawDeck); // Update the drawing deck state
    };

    // Function to open the dialog and draw the hand
    const handleOpen = () => {
        drawStartingHand();
        setShowModal(true);
    };

    // Function to close the dialog
    const handleClose = () => {
        setShowModal(false);
    };

    // Function to draw one additional card
    const handleDraw = () => {
        if (drawDeck.length === 0) return;

        let randomIndex = Math.floor(Math.random() * drawDeck.length);
        let card = { ...drawDeck[randomIndex] }; // Clone the card object

        if (card.count > 0) {
            setHand(prevHand => [...prevHand, card]);
            drawDeck[randomIndex].count--; // Decrease the count in the drawing deck

            if (drawDeck[randomIndex].count === 0) {
                setDrawDeck(drawDeck.filter((_, idx) => idx !== randomIndex));
            } else {
                setDrawDeck([...drawDeck]);
            }
        }
    };

    return (
        <div>
            <img onClick={handleOpen} src='assets/testhandlogo.png' className='settingsLogo' alt="egmanlogo" />


            {/* Dialog to display the drawn hand */}
            <Dialog open={showModal} onClose={handleClose} maxWidth="md" fullWidth style={{ backgroundColor: "#000000e6" }}>
                <IconButton
                    edge="end"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 24, top: 8 }}
                >
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <ul style={{ minHeight: '450px', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                        {hand.map((card, index) => (
                            <img
                                key={index} // Adding a key prop to each card
                                src={`/card_images/${selectedCardType}/${card.ArtSrc ? `${card.ArtSrc}.webp` : `${card.Key}.webp`}`}
                                alt={card['Card Name']}
                                loading="lazy"
                                className="cardInHand"
                                style={{ width: '150px' }}
                            />
                        ))}
                    </ul>
                    <div style={{ display: 'flex', width: '50%', justifyContent: 'space-evenly' }}>
                        <Button className="modalButton" variant="contained" onClick={handleOpen}>New Hand</Button>
                        <Button className="modalButton" variant="contained" onClick={handleDraw}>Draw</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default TestHands;
